
import axios from 'src/app-axios';
import Constants from 'src/constants';

export const bannerex = {
    namespaced: true,
    state: {
      buildRecord: {},
      progress: 0,
    },

    getters: {
      getDownloadProgress (state) {
        return state.progress;
      },
    },

    mutations: {
      setBuildRecord (state, buildRecord) {
        state.buildRecord = buildRecord;
      },
      setProgress (state, progress) {
        state.progress = progress;
      },
      clearOffProgressStatus (state) {
        state.progress = 0;
      },
    },

    actions: {
      async getLatestBuildRecord ({ dispatch, commit }, operatingSystem) {
        const url = `api/banner-ex/latest?operating_system=${operatingSystem}`;
        try {
         const response = await axios({
            url: url,
            method: 'GET',
          });

          commit('setBuildRecord', response.data);
          return response;
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in downloading bannerEx file.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },

      downloadBannerEx ({ dispatch, commit }, { recordId, recordFileName }) {
        const url = `api/banner-ex/${recordId}/download`;
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress: (event) => {
              const progress = parseInt((event.loaded / event.total) * 100);
              commit('setProgress', progress);
            },
          }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', recordFileName);
            document.body.appendChild(link);
            link.click();
          }).catch(exception => {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in downloading bannerEx file.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        });
      },
      clearProgressStatus ({ commit }) {
        commit('clearOffProgressStatus');
      },
    },
};
