import axios from 'src/app-axios';
import Constants from 'src/constants';

export const contents = {
    namespaced: true,
    state: {
        savedContents: [],
        templates: [],
        posterMyWallAuthenticationDetails: {},
    },
    getters: {
        getSavedContents (state) {
            return state.savedContents;
        },
        getTemplateContents (state) {
            return state.templates;
        },
        getPosterMyWallAuthenticationDetails (state) {
            return state.posterMyWallAuthenticationDetails;
        },
    },
    mutations: {
        addSavedContents (state, payload) {
            state.savedContents = payload;
        },
        addTemplateContents (state, payload) {
            state.templates = payload;
        },
        removeSavedContent (state, uniqueFileName) {
            const index = state.savedContents.map(item => item.unique_file_name).indexOf(uniqueFileName);
            state.savedContents.splice(index, 1);
        },
        removeTemplateContent (state, uniqueFileName) {
            const index = state.templates.map(item => item.unique_file_name).indexOf(uniqueFileName);
            state.templates.splice(index, 1);
        },
        clearOffSavedContents (state) {
            state.savedContents = [];
        },
        clearOffTemplates (state) {
            state.templates = [];
        },
        setPosterMyWallAuthenticationDetails (state, details) {
            state.posterMyWallAuthenticationDetails = details;
        },
    },
    actions: {
        onSavedContentRemove (context, uniqueFileName) {
            context.commit('removeSavedContent', uniqueFileName);
        },
        onTemplateContentRemove (context, uniqueFileName) {
            context.commit('removeTemplateContent', uniqueFileName);
        },
        async fetchSavedContents ({ dispatch, commit }, { params }) {
            const url = '/api/banners/content';
            try {
               const response = await axios.get(url, { params: params });
               commit('addSavedContents', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your saved contents.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTemplates ({ dispatch, commit }, { params }) {
            const url = '/api/banners/content';
            try {
               const response = await axios.get(url, { params: params });
               commit('addTemplateContents', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your templates.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async uploadContent ({ dispatch }, formData) {
            const url = 'api/banners/content/upload';
            try {
                await axios.post(url, formData);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in uploading your content.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearSavedContents ({ commit }) {
            commit('clearOffSavedContents');
        },
        clearTemplates ({ commit }) {
            commit('clearOffTemplates');
        },
        async fetchSelectedFolderSavedContents ({ dispatch, commit }, { params }) {
            const url = '/api/banners/content';
            try {
               const response = await axios.get(url, { params: params });
               commit('addSavedContents', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your saved contents.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchSelectedFolderTemplates ({ dispatch, commit }, { params }) {
            const url = '/api/banners/content';
            try {
               const response = await axios.get(url, { params: params });
               commit('addTemplateContents', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your templates.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchPosterMyWallAuthenticationDetails ({ dispatch, commit }) {
            const url = 'api/content-editor/auth-uri';
            try {
                const details = await axios.get(url);
                commit('setPosterMyWallAuthenticationDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting PosterMyWall authentication details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async passPosterMyWallUserAuthDetails ({ dispatch }, details) {
            const url = 'api/content-editor/oauth-token';
            try {
                await axios.post(url, details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in registering your PosterMyWall account with CMS.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async sendPosterMyWallContentDetailsToServer ({ dispatch }, details) {
            const url = 'api/content-editor/download';
            try {
                await axios.post(url, details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading your PosterMyWall content.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
