import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../app-axios';

// Module imports
import { contents } from './modules/contents.module';
import { alert } from './modules/alert.module';
import { records } from './modules/records.module';
import { orgsettings } from './modules/orgsettings.module';
import { digiboards } from './modules/digiboards.module';
import { reports } from './modules/reports.module';
import { dashboard } from './modules/dashboard.module';
import { schedule } from './modules/schedule.module';
import { support } from './modules/support.module';
import { banners } from './modules/banners.module';
import { campaigns } from './modules/campaigns.module';
import { registration } from './modules/registration.module';
import { notifications } from './modules/notifications.module';
import { passwordreset } from './modules/passwordreset.module';
import { grouping } from './modules/grouping.module';
import { mediafile } from './modules/mediafile.module';
import { userprofile } from './modules/userprofile.module';
import { publish } from './modules/publish.module';
import { bannerex } from './modules/bannerex.module';
import { accountsettings } from './modules/accountsettings.module';
import { users } from './modules/users.module';
import { payments } from './modules/payments.module';
import { widgets } from './modules/widgets.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/sidebar-3.jpg'),
    drawer: null,
    user: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload;
    },
    setUserData (state, userData) {
      state.user = userData;
      localStorage.setItem('ds-user', JSON.stringify(userData));
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    },

    clearUserData () {
      localStorage.removeItem('ds-user');
      localStorage.removeItem('sc-user');
      localStorage.removeItem('scp-user');
      location.reload();
    },
    clearLocalStorage () {
      localStorage.removeItem('ds-user');
      localStorage.removeItem('sc-user');
      localStorage.removeItem('scp-user');
    },
  },
  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('api/login', credentials)
        .then(({ data }) => {
          commit('setUserData', data);
        });
    },

    logout ({ commit }) {
      commit('clearUserData');
    },
    clearStorage ({ commit }) {
      commit('clearLocalStorage');
    },
  },

  getters: {
    isLogged: state => !!state.user,
    getUser (state) {
      return state.user;
    },
  },

  modules: {
    contents,
    alert,
    records,
    orgsettings,
    digiboards,
    reports,
    dashboard,
    schedule,
    support,
    banners,
    campaigns,
    registration,
    passwordreset,
    notifications,
    grouping,
    mediafile,
    userprofile,
    publish,
    bannerex,
    accountsettings,
    users,
    payments,
    widgets,
  },
});
