import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const users = {
    namespaced: true,
    state: {
        usersList: [],
        permissionGroupDetails: {},
    },
    getters: {
        getUsersList (state) {
            return state.usersList;
        },
        getpermissionGroupDetails (state) {
            return state.permissionGroupDetails;
        },
    },
    mutations: {
        setUsersList (state, data) {
            state.usersList = data;
        },
        setpermissionGroupDetails (state, data) {
            state.permissionGroupDetails = data;
        },
        clearPermissionGroupDetails (state) {
            state.permissionGroupDetails = {};
        },
    },
    actions: {
        async fetchUsersList ({ dispatch, commit }) {
            const url = 'api/user-admin/users';
            try {
                const details = await appAxios.get(url);
                commit('setUsersList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async createUser ({ dispatch }, payload) {
            const url = 'api/user-admin/users';
            try {
                await appAxios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating a new user.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchPermissionGroupDetails ({ dispatch, commit }, payload) {
            const url = `api/user-admin/permissions/groups/${payload}`;
            {
                const details = await appAxios.get(url);
                commit('setpermissionGroupDetails', details.data);
            }
        },
        clearPermissionGroupDetails ({ commit }) {
            commit('clearPermissionGroupDetails');
        },
        async deleteUser ({ dispatch }, deleteId) {
            const url = `api/user-admin/users/${deleteId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in removing User.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async assignPermissionGroup ({ dispatch }, payload) {
            const url = `api/user-admin/users/${payload.userId}/permission-groups/${payload.groupId}`;
              await appAxios.put(url, payload);
        },
    },
};
