import axios from 'src/app-axios';
import Constants from 'src/constants';

export const accountsettings = {
    namespaced: true,
    state: {
      permissionGroups: [],
      permissionsList: [],
      permissionCategoryList: [],
    },

    getters: {
      getPermissionGroups (state) {
        return state.permissionGroups;
      },
      getPermissionsList (state) {
        return state.permissionsList;
      },
      getPermissionCategoryList (state) {
        return state.permissionCategoryList;
      },
    },

    mutations: {
      setPermissionGroups (state, groups) {
        state.permissionGroups = groups;
      },
      setPermissionsList (state, permissions) {
        state.permissionsList = permissions;
      },
      setPermissionCategoryList (state, categories) {
        state.permissionCategoryList = categories;
      },
    },

    actions: {
      async fetchPermissionGroups ({ dispatch, commit }) {
        const url = 'api/user-admin/permissions/groups';
        try {
          const details = await axios.get(url);
          commit('setPermissionGroups', details.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting your permission groups.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchPermissionsList ({ dispatch, commit }) {
        const url = 'api/user-admin/permissions';
        try {
          const details = await axios.get(url);
          commit('setPermissionsList', details.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the permissions list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchPermissionCategoryList ({ dispatch, commit }) {
        const url = 'api/user-admin/permissions/categories';
        try {
          const details = await axios.get(url);
          commit('setPermissionCategoryList', details.data.permission_categories);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the permission categories.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async createPermissionGroup ({ dispatch }, payload) {
        const url = 'api/user-admin/permissions/groups';
        try {
          await axios.post(url, payload);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in creating your new permission group.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      async updatePermissionGroup ({ dispatch }, payload) {
        const url = `api/user-admin/permissions/groups/${payload.groupId}`;
        try {
          await axios.put(url, {
            group_name: payload.groupName,
            permissions: payload.selectedPermissions,
          });
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in updating your permission group.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
    },
};
