import axios from 'src/app-axios';
import Constants from 'src/constants';

export const reports = {
    namespaced: true,
    state: {
        graphData: [],
        formData: {},
    },
    getters: {
        getGraphData (state) {
            return state.graphData;
        },
        getFormData (state) {
            return state.formData;
        },
    },
    mutations: {
        setGraphData (state, graphData) {
            state.graphData = graphData;
        },
        setFormData (state, formData) {
            state.formData = formData;
        },
    },
    actions: {
        async fetchGraphData ({ dispatch, commit, state }, payload) {
            const url = 'api/search/history';
            try {
                const response = await axios.post(url, payload);
                const results = response.data.aggregations.attribCount.buckets;
                if (state.formData.reportType !== 'Banner Count') {
                    commit('setGraphData', results);
                    return;
                }
                dispatch('fetchBannerData', results);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your report.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        onCurrentFormData ({ commit }, formData) {
            commit('setFormData', formData);
        },
        onCurrentGraphData ({ commit }, graphData) {
            commit('setGraphData', graphData);
        },
        async fetchBannerData ({ dispatch, commit }, payload) {
          const finalResult = [];
          for (const result of payload) {
            try {
              const response = await axios.get(`api/banners/${result.key}`);

              finalResult.push({
                key: response.data.name,
                doc_count: result.doc_count,
              });
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your report.',
                type: Constants.ALERT_TYPE_ERROR,
              });
            }
          }
          commit('setGraphData', finalResult);
        },
    },
};
