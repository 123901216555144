<template>
  <div>
    <router-view />
    <dbs-alert />
    <progress-bar />
  </div>
</template>

<script>
  import Alert from './views/dashboard/component/Alert';
  import ProgressBar from './views/dashboard/component/ProgressBar.vue';

  export default {
    name: 'App',
    components: {
      'dbs-alert': Alert,
      ProgressBar,
    },
  };

</script>
<style lang="scss">
  @import './assets/global.css';
</style>
