import axios from 'src/app-axios';
import Constants from 'src/constants';

export const grouping = {
    namespaced: true,
    state: {
        createdStructure: [],
        savedStructure: {},
    },
    getters: {
        getCreatedStructure (state) {
            return state.createdStructure;
        },
        getSavedStructure (state) {
            return state.savedStructure;
        },
    },
    mutations: {
        setCreatedStructure (state, data) {
            state.createdStructure = data;
        },
        setSavedStructure (state, data) {
            state.savedStructure = data;
        },
    },
    actions: {
        createStructure (context, payload) {
            context.commit('setCreatedStructure', payload);
        },
        async groupDigiBoards  ({ dispatch, commit }, payload) {
            const url = 'api/digi-boards/group';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating the device group.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchSavedStructure ({ dispatch, commit }) {
            const url = 'api/digi-boards/group';
            try {
                const details = await axios.get(url);
                commit('setSavedStructure', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your grouped devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
