export const alert = {
    namespaced: true,
    state: {
        message: '',
        type: '',
    },
    getters: {},
    actions: {
        onAlert (context, alert) {
            context.commit('addAlert', alert);
        },
    },
    mutations: {
        addAlert (state, alert) {
            state.message = alert.message;
            state.type = alert.type;
        },
    },
};
