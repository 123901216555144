import axios from 'src/app-axios';
import Constants from 'src/constants';

export const payments = {
    namespaced: true,
    state: {
        productPaymentPlans: [],
        checkoutSession: {},
        subscriptionsHistory: [],
        invoiceUrls: {},
        purchaseDetails: {},
        oneTimePaymentHistory: [],
        trialEndsAtNotification: {},
    },
    getters: {
        getProductPaymentPlans (state) {
            return state.productPaymentPlans;
        },
        getCheckoutSession (state) {
            return state.checkoutSession;
        },
        getSubscriptionsHistory (state) {
            return state.subscriptionsHistory;
        },
        getInvoiceDownloadUrl (state) {
            return state.invoiceUrls;
        },
        getPurchaseDetails (state) {
            return state.purchaseDetails;
        },
        getOneTimePaymentHistory (state) {
            return state.oneTimePaymentHistory;
        },
        getTrialEndsAtNotification (state) {
            return state.trialEndsAtNotification;
        },
    },
    mutations: {
        setProductPaymentPlans (state, plans) {
            state.productPaymentPlans = plans;
        },
        setCheckoutSession (state, session) {
            state.checkoutSession = session;
        },
        setSubscriptionsHistory (state, history) {
            state.subscriptionsHistory = history;
        },
        setInvoiceDownloadUrl (state, data) {
            state.invoiceUrls = data;
        },
        setPurchaseDetails (state, details) {
            state.purchaseDetails = details;
        },
        setOneTimePaymentHistory (state, history) {
            state.oneTimePaymentHistory = history;
        },
        setTrialEndsAtNotification (state, message) {
            state.trialEndsAtNotification = message;
        },
    },
    actions: {
        async fetchProductPaymentPlans ({ dispatch, commit }) {
            const url = 'api/payments/products/bannerEx';
            try {
                const details = await axios.get(url);
                commit('setProductPaymentPlans', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the product subscription list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async createCheckoutSession ({ dispatch, commit }, payload) {
            const url = 'api/payments/session-create';
            try {
                const response = await axios.post(url, payload);
                commit('setCheckoutSession', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating your checkout session.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createCheckoutCustomer ({ dispatch }, payload) {
            const url = 'api/payments/users';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating customer for checkout.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async checkoutSessionCompleted  ({ dispatch, commit }, payload) {
            const url = 'api/payments/session-completed';
            try {
                const details = await axios.post(url, payload);
                commit('setPurchaseDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in completing your checkout session.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchSubscriptionsHistory ({ dispatch, commit }) {
            const url = 'api/payments/subscriptions';
            try {
                const details = await axios.get(url);
                commit('setSubscriptionsHistory', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your payments history.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchInvoiceDownloadUrl ({ dispatch, commit }, payload) {
            const url = `api/payments/${payload.purchaseMode}/${payload.subscriptionId}/invoices`;
            try {
                const details = await axios.get(url);
                commit('setInvoiceDownloadUrl', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading your purchase invoice.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchOneTimePaymentHistory ({ dispatch, commit }) {
            const url = 'api/payments/payment-intents';
            try {
                const details = await axios.get(url);
                commit('setOneTimePaymentHistory', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your payments history.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        recordTrialEndsAtNotification (context, message) {
            context.commit('setTrialEndsAtNotification', message);
        },
    },
};
