import axios from 'src/app-axios';
import Constants from 'src/constants';

export const schedule = {
    namespaced: true,
    state: {
        advancedScheduleData: [],
        editAdvancedScheduleData: [],
    },
    getters: {
        getScheduleData (state) {
            return state.advancedScheduleData;
        },
        getAdvancedScheduleForEdit (state) {
            return state.editAdvancedScheduleData;
        },
    },
    mutations: {
        setAdvancedScheduleData (state, data) {
            state.advancedScheduleData.push(data);
        },
        clearAdvancedScheduleData (state) {
            state.advancedScheduleData = [];
        },
        removeAdvancedScheduleData (state, index) {
            state.advancedScheduleData.splice(index, 1);
        },
        setAdvancedScheduleForEdit (state, data) {
            state.editAdvancedScheduleData.push(data);
        },
        clearOffAdvancedScheduleForEdit (state) {
            state.editAdvancedScheduleData = [];
        },
        removeExistingAdvancedScheduleData (state, index) {
            state.editAdvancedScheduleData.splice(index, 1);
        },
    },
    actions: {
        async fetchAdvancedScheduleData ({ dispatch, commit }, payload) {
            const url = 'api/schedule/weekly';
            try {
                const response = await axios.post(url, payload);
                commit('setAdvancedScheduleData', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating your advanced schedule.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearAdvancedSchedule ({ commit }) {
            commit('clearAdvancedScheduleData');
        },
        deleteAdvancedScheduleData (context, index) {
            context.commit('removeAdvancedScheduleData', index);
        },
        async fetchAdvancedScheduleForEdit ({ dispatch, commit, state }, payload) {
            const url = `api/schedule/weekly/${payload}`;
            try {
                const details = await axios.get(url);
                commit('setAdvancedScheduleForEdit', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your advanced schedule list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearAdvancedScheduleForEdit ({ commit }) {
            commit('clearOffAdvancedScheduleForEdit');
        },
        async updateAdvancedScheduleData ({ dispatch, commit }, payload) {
            const url = `api/schedule/weekly/${payload.id}`;
            try {
                await axios.put(url, {
                    days: payload.days,
                    start: payload.start,
                    end: payload.end,
                });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your advanced schedule.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteExistingSchedule ({ dispatch, commit }, payload) {
            const url = `api/schedule/weekly/${payload.scheduleId}`;
            try {
                await axios.delete(url);
                commit('removeExistingAdvancedScheduleData', payload.deleteIndex);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting the schedule.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
