import axios from 'src/app-axios';
import Constants from 'src/constants';

export const mediafile = {
    namespaced: true,
    state: {
      mediaFile: {},
      mediaFolders: [],
      editMediaFolderDetails: {},
    },
    getters: {
      getMediaFile (state) {
        return state.mediaFile;
      },
      getMediaFolders (state) {
        return state.mediaFolders;
      },
      getMediaFolderDetailsForEdit (state) {
        return state.editMediaFolderDetails;
      },
    },
    mutations: {
      setMediaFile (state, data) {
        state.mediaFile = data;
      },
      setMediaFolders (state, folders) {
        state.mediaFolders = folders;
      },
      setMediaFolderDetailsForEdit (state, data) {
        state.editMediaFolderDetails = data;
      },
      clearOffMediaFolderDetails (state) {
        state.editMediaFolderDetails = {};
      },
    },
    actions: {
      async upload ({ dispatch, commit }, payload) {
        const url = 'api/media-files';
        try {
            const response = await axios.post(url, payload);
            commit('setMediaFile', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in uploding the file.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async createNewMediaFolder ({ dispatch }, payload) {
        const url = 'api/folders';
        try {
          await axios.post(url, payload);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in creating your new media folder',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      async fetchMediaFolders ({ dispatch, commit }) {
        const url = 'api/folders';
        try {
            const details = await axios.get(url);
            commit('setMediaFolders', details.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting your banners list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchMediaFolderDetailsForEdit ({ dispatch, commit }, payload) {
        const url = `api/folders/${payload.folderId}`;
        try {
            const details = await axios.get(url);
            commit('setMediaFolderDetailsForEdit', details.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the folder details',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      async updateMediaFolderName ({ dispatch }, payload) {
        const url = `api/folders/${payload.folderId}`;
        try {
          await axios.put(url, {
            name: payload.folderName,
            description: payload.folderDescription,
            global: payload.shared,
            icon: payload.selectedIcon,
          });
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in updating the folder name.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            throw exception;
        }
      },
      clearMediaFolderDetails ({ commit }) {
        commit('clearOffMediaFolderDetails');
      },
    },
};
