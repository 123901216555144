import axios from 'src/app-axios';
import Constants from 'src/constants';

export const widgets = {
    namespaced: true,
    state: {
        tickerSettings: [],
        tickerContents: {},
        tickerList: [],
        organizationTickerSetting: {},
    },
    getters: {
        getTickerSettingsList (state) {
            return state.tickerSettings;
        },
        getTickerContents (state) {
            return state.tickerContents;
        },
        getTickerList (state) {
            return state.tickerList;
        },
        getOrganizationTickerSetting (state) {
            return state.organizationTickerSetting;
        },
    },
    mutations: {
        setTickerSettingsList (state, settings) {
            state.tickerSettings = settings;
        },
        setTickerContents (state, data) {
            state.tickerContents = data;
        },
        clearOffTickerContents (state) {
            state.tickerContents = {};
        },
        setTickerList (state, data) {
            state.tickerList = data;
        },
        setOrganizationTickerSetting (state, settings) {
            state.organizationTickerSetting = settings;
        },
    },
    actions: {
       async fetchTickerSettingsList ({ dispatch, commit }) {
            const url = 'api/ticker-settings';
            try {
                const settings = await axios.get(url);
                commit('setTickerSettingsList', settings.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the ticker settings list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async applyToBoards ({ dispatch }, payload) {
            const url = `api/ticker-settings/${payload.settings_id}/digi-boards`;
            try {
                await axios.put(url, payload.digiBoardsArray);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in applying your ticker settings to devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createTicker ({ dispatch, commit }, payload) {
            const url = 'api/tickers';
            try {
                const response = await axios.post(url, payload);
                commit('setTickerContents', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating your ticker.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async linkTickerToBoard ({ dispatch }, payload) {
            const url = `api/tickers/${payload.tickerId}/digi-boards`;
            try {
                await axios.post(url, payload.digiBoards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in linking your ticker to devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearTickerContents ({ commit }) {
            commit('clearOffTickerContents');
        },
        async fetchTickersList ({ dispatch, commit }) {
            const url = 'api/tickers';
            try {
                const details = await axios.get(url);
                commit('setTickerList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your ticker list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async deleteTicker ({ dispatch }, tickerId) {
            const url = `api/tickers/${tickerId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in removing the ticker.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateTicker ({ dispatch }, payload) {
            const url = `api/tickers/${payload.TickerId}`;
            try {
                await axios.put(url, payload.tickerUpdateData);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your ticker.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateTickerToBoardLink ({ dispatch }, payload) {
            const url = `api/tickers/${payload.ticker_id}/digi-boards`;
            try {
                await axios.put(url, payload.digiBoards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your ticker link with digi-boards.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchOrganizationTickerSetting ({ dispatch, commit }) {
            const url = 'api/ticker-settings/organization';
            try {
                const settings = await axios.get(url);
                commit('setOrganizationTickerSetting', settings.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your organization ticker setting.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
