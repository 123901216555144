import axios from 'src/app-axios';
import Constants from 'src/constants';

export const publish = {
    namespaced: true,
    state: {
        selectedBoardsForPublish: [],
    },
    getters: {
        getSelectedBoards (state) {
            return state.selectedBoardsForPublish;
        },
    },
    mutations: {
        setSelectedBoards (state, boards) {
            state.selectedBoardsForPublish.push(boards);
        },
        clearOffSelectedBoards (state) {
            state.selectedBoardsForPublish = [];
        },
    },
    actions: {
        addSelectedBoardsForPublish (context, selectedBoards) {
            context.commit('setSelectedBoards', selectedBoards);
        },
        async publishContents ({ dispatch }, payload) {
            const url = 'api/digi-boards/publish';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in publishing your contents to devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearSelectedBoards ({ commit }) {
            commit('clearOffSelectedBoards');
        },
    },
};
