export const backendUrl = process.env.VUE_APP_BACKEND_API_URL;
export const storageUrl = process.env.VUE_APP_STORAGE_API_URL;
export const cdnUrl = process.env.VUE_APP_CDN_URL;
export const pusherKey = process.env.VUE_APP_PUSHER_KEY;
export const pusherCluster = process.env.VUE_APP_PUSHER_CLUSTER;
export const posterMyWallEditorUrl = process.env.VUE_APP_POSTERMYWALL_EDITOR_URL;
export const stripePublishKey = process.env.VUE_APP_STRIPE_PUBLISH_KEY;
export const userLocationUrl = process.env.VUE_APP_USER_LOCATION_URL;
export const scAppDomainUrl = process.env.VUE_APP_SC_DOMAIN_URL;
export const scpAppDomainUrl = process.env.VUE_APP_SCP_DOMAIN_URL;
export const signageAppDomainUrl = process.env.VUE_APP_SIGNAGE_DOMAIN_URL;
