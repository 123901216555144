<template>
  <v-dialog
    v-model="showProgressDialog"
    hide-overlay
    no-click-animation
    persistent
    max-width="400"
  >
    <v-card color="#ECEFF1">
      <v-card-title class="my-title-style">
        {{ title }}
      </v-card-title>
      <v-col
        cols="24"
        class="text-center"
      >
        <v-progress-linear
          v-model="progress"
          height="30"
          dark
          color="#FF3700"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';

export default {
    name: 'ProgressBar',
    props: {
      title: {
        type: String,
        default: 'Downloading BannerEx...',
      },
    },
    data () {
      return {
        progress: 0,
      };
    },
    computed: {
      getDownloadProgress () {
        return this.$store.getters['bannerex/getDownloadProgress'];
      },
      showProgressDialog () {
        if (this.getDownloadProgress > 0) {
          return true;
        }
        return false;
      },
    },
    watch: {
      getDownloadProgress (val) {
        if (val === 100) {
          this.$store.dispatch('bannerex/clearProgressStatus');
          setTimeout(() => {
            this.$store.dispatch('alert/onAlert', {
              message: 'BannerEx downloaded successfully, Please check your downloads folder.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
          }, 3000);
        }
      },
    },
    created () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'bannerex/setProgress') {
          this.showDownloadProgress(state.bannerex.progress);
        }
      });
    },
    methods: {
      showDownloadProgress (progress) {
        this.progress = progress;
      },
    },
};
</script>
<style scoped>
.my-title-style {
    color: #37474F;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    font-weight: bolder;
}
 .my-custom-dialog {
    align-self: flex-end;
  }
.v-dialog__content {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
