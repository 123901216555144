<script>
  import 'he-tree-vue/dist/he-tree-vue.css';
  import { Tree, Fold, Check, Draggable } from 'he-tree-vue';
  export default {
    extends: Tree,
    mixins: [Fold, Check, Draggable],
    props: {
      draggable: { type: [Boolean, Function], default: false },
      droppable: { type: [Boolean, Function], default: false },
    },
    data () {
      return {
        treeClass: 'my-tree-view1',
      };
    },
    methods: {
      overrideSlotDefault ({ node, index, path, tree }, original) {
        return <div class="node-content">
        <button class="mrs fold-btn" onClick={() => tree.toggleFold(node, path)}>{node.$folded ? '+' : '-'}</button>
        {original()}
      </div>;
      },
    },
  };
</script>

<style>
.my-tree-view1 .mls{
  margin-left: 5px;
}
.my-tree-view1 .mrs{
  margin-right: 5px;
}
.my-tree-view1 .tree-node{
  padding: 0;
  border: none;
}
.my-tree-view1 .node-content{
  display: flex;
}
.my-tree-view1 .node-content .fold-btn{
  display: flex;
  justify-content: center;
  width: 22px;
  border-radius: 100%;
  border: none;
  background: #fcf1a8;
}
.my-tree-view1 .tree-node-back:hover{
  background: #f5f5f5;
}
.my-tree-view1 .header{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.my-tree-view1 .footer{
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
</style>
