import axios from 'src/app-axios';
import Constants from 'src/constants';

export const dashboard = {
    namespaced: true,
    state: {
        leftChart: [],
        leftChartDescription: {},
        middleChart: [],
        middleChartDescription: {},
        rightChart: [],
        rightChartDescription: {},
        userActivityChart: [],
        userActivityChartDescription: {},
        dataUsageStatistics: {},
    },
    getters: {
        getLeftChart (state) {
            return state.leftChart;
        },
        getLeftChartDescription (state) {
            return state.leftChartDescription;
        },
        getMiddleChart (state) {
            return state.middleChart;
        },
        getMiddleChartDescription (state) {
            return state.middleChartDescription;
        },
        getRightChart (state) {
            return state.rightChart;
        },
        getRightChartDescription (state) {
            return state.rightChartDescription;
        },
        getUserActivityChart (state) {
            return state.userActivityChart;
        },
        getUserActivityChartDescription (state) {
            return state.userActivityChartDescription;
        },
        getDataUsageStatistics (state) {
            return state.dataUsageStatistics;
        },
    },
    mutations: {
        setLeftChart (state, data) {
            state.leftChart.push(data);
        },
        setLeftChartDescription (state, payload) {
            state.leftChartDescription = payload;
        },
        setMiddleChart (state, data) {
            state.middleChart.push(data);
        },
        setMiddleChartDescription (state, payload) {
            state.middleChartDescription = payload;
        },
        setRightChart (state, data) {
            state.rightChart.push(data);
        },
        setRightChartDescription (state, payload) {
            state.rightChartDescription = payload;
        },
        setUserActivityChart (state, data) {
            state.userActivityChart.push(data);
        },
        setUserActivityChartDescription (state, payload) {
            state.userActivityChartDescription = payload;
        },
        setDataUsageStatistics (state, data) {
            state.dataUsageStatistics = data;
        },
    },
    actions: {
        async fetchLeftChartData ({ dispatch, commit, state }, { query, label, id }) {
            if (state.leftChart.length > 0) {
                return;
            }
            const url = 'api/search/history';
            try {
                const response = await axios.post(url, query);
                const results = response.data.aggregations.attribCount.buckets;
                commit('setLeftChart', { id: id, label: label, count: results[1].doc_count });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your cycle completed banner count.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        fetchLeftChartDescription ({ state, commit }, payload) {
            if (Object.keys(state.leftChartDescription).length > 0) {
                return;
            }
            commit('setLeftChartDescription', payload);
        },
        async fetchMiddleChartData ({ dispatch, commit, state }, { query, label, id }) {
            if (state.middleChart.length > 0) {
                return;
            }
            const url = 'api/search/history';
            try {
                const response = await axios.post(url, query);
                const results = response.data.aggregations.attribCount.buckets;
                commit('setMiddleChart', { id: id, label: label, count: results.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your weekly banners count.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        fetchMiddleChartDescription ({ state, commit }, payload) {
            if (Object.keys(state.middleChartDescription).length > 0) {
                return;
            }
            commit('setMiddleChartDescription', payload);
        },
        async fetchRightChartTotalData ({ dispatch, commit, state }, { params, label, color }) {
            if (state.rightChart.length > 0) {
                return;
            }
            const url = 'api/digi-boards/health';
            try {
                const response = await axios.get(url, { params: params });
                const total = response.data;
                commit('setRightChart', { color: color, label: label, count: total.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your Board health status.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchRightChartSuccessfulData ({ dispatch, commit, state }, { params, label, color }) {
            if (state.rightChart.length > 0) {
                return;
            }
            const url = 'api/digi-boards/health';
            try {
                const response = await axios.get(url, { params: params });
                var successful = response.data;
                commit('setRightChart', { color: color, label: label, count: successful.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your Board health status.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchRightChartFailedData ({ dispatch, commit, state }, { params, label, color }) {
            if (state.rightChart.length > 0) {
                return;
            }
            const url = 'api/digi-boards/health';
            try {
                const response = await axios.get(url, { params: params });
                var failed = response.data;
                var count = 0;
                if (failed.length > 0) {
                    count = failed.length;
                }
                commit('setRightChart', { color: color, label: label, count: count });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your Board health status.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        fetchRightChartDescription ({ state, commit }, payload) {
            if (Object.keys(state.rightChartDescription).length > 0) {
                return;
            }
            commit('setRightChartDescription', payload);
        },
        async fetchUserCreatedChart ({ dispatch, commit, state }, { params, label }) {
            if (state.userActivityChart.length > 0) {
                return;
            }
            const url = 'api/search/user-activity';
            try {
                const response = await axios.get(url, { params: params });
                var created = response.data;
                commit('setUserActivityChart', { label: label, count: created.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your previous month activity data.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchUserDeletedChart ({ dispatch, commit, state }, { params, label }) {
            if (state.userActivityChart.length > 0) {
                return;
            }
            const url = 'api/search/user-activity';
            try {
                const response = await axios.get(url, { params: params });
                var deleted = response.data;
                commit('setUserActivityChart', { label: label, count: deleted.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your previous month activity data.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchUserUpdatedChart ({ dispatch, commit, state }, { params, label }) {
            if (state.userActivityChart.length > 0) {
                return;
            }
            const url = 'api/search/user-activity';
            try {
                const response = await axios.get(url, { params: params });
                var updated = response.data;
                commit('setUserActivityChart', { label: label, count: updated.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your previous month activity data.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        fetchUserActivityChartDescription ({ commit, state }, payload) {
            if (Object.keys(state.userActivityChartDescription).length > 0) {
                return;
            }
            commit('setUserActivityChartDescription', payload);
        },
        async fetchDataUsageStatistics ({ dispatch, commit }, { params }) {
            const url = 'api/users/data-usage/statistics';
            try {
                const response = await axios.get(url, { params: params });
                  commit('setDataUsageStatistics', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your data usage statistics.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
