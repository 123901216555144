import appAxios from 'src/app-axios';
import Constants from 'src/constants';
import axios from 'axios';
import { userLocationUrl } from 'src/app-globals';

export const userprofile = {
    namespaced: true,
    state: {
        userProfile: {},
        countryCodes: {},
        userLocationDetails: {},
        organizationDetails: {},
        timeZones: {},
        sciUser: null,
        scpUser: null,
    },
    getters: {
        getUserProfile (state) {
            return state.userProfile;
        },
        getCountryCodes (state) {
            return state.countryCodes;
        },
        getUserLocation (state) {
            return state.userLocationDetails;
        },
        getOrganizationDetails (state) {
            return state.organizationDetails;
        },
        getTimeZones (state) {
            return state.timeZones;
        },
        getSciUser (state) {
            return state.sciUser;
        },
        getScpUser (state) {
            return state.scpUser;
        },
    },
    mutations: {
        setUserProfile (state, data) {
            state.userProfile = data;
        },
        setCountryCodes (state, data) {
            state.countryCodes = data;
        },
        setUserLocation (state, details) {
            state.userLocationDetails = details;
        },
        setOrganizationDetails (state, details) {
            state.organizationDetails = details;
        },
        setTimeZones (state, details) {
            state.timeZones = details;
        },
        setSciUser (state, details) {
            state.sciUser = details;
            localStorage.setItem('sc-user', JSON.stringify(details));
        },
        setScpUser (state, details) {
            state.scpUser = details;
            localStorage.setItem('scp-user', JSON.stringify(details));
        },
    },
    actions: {
       async fetchUserProfile ({ dispatch, commit }) {
            const url = 'api/users';
            try {
                const details = await appAxios.get(url);
                commit('setUserProfile', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the user details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchCountryCodes ({ dispatch, commit }) {
            const url = 'api/country-codes';
            try {
                const details = await appAxios.get(url);
                commit('setCountryCodes', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting country zip code.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateUserProfile ({ dispatch }, payload) {
            const url = `api/users/${payload.referenceId}`;
            try {
                await appAxios.put(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating user profile.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchUserLocation ({ dispatch, commit }) {
            const url = userLocationUrl;
            try {
                const details = await axios.get(url);
                commit('setUserLocation', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your location details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchOrganizationDetails ({ dispatch, commit }) {
            const url = 'api/organization';
            try {
                const details = await appAxios.get(url);
                commit('setOrganizationDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your organization details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateOrganizationDetails ({ dispatch }, payload) {
            const url = 'api/organizations/update';
            try {
                await appAxios.put(url, payload.editOrganizationDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating organization details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTimeZones ({ dispatch, commit }) {
            const url = 'api/timezones';
            try {
                const details = await appAxios.get(url);
                commit('setTimeZones', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting TimeZone.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async loginToSwiftCheckIn ({ dispatch, commit }, payload) {
            const url = `api/swift-checkin/login/${payload.userId}`;
            try {
                const details = await appAxios.post(url);
                commit('setSciUser', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in login to swift check-in.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async loginToSwiftCheckInProjects ({ dispatch, commit }, payload) {
            const url = `api/swift-checkin-projects/login/${payload.userId}`;
            try {
                const details = await appAxios.post(url);
                commit('setScpUser', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in login to swift check-in.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
