import axios from 'src/app-axios';
import Constants from 'src/constants';

export const banners = {
    namespaced: true,
    state: {
        activeBanners: {},
        expiredBanners: {},
        scheduledBanners: {},
        bannerStatusData: [],
        bannerStatusChartDescription: {},
        bannerFile: [],
        bannerName: '',
        banner: {},
        bannersList: [],
    },
    getters: {
        getActiveBanners (state) {
            return state.activeBanners;
        },
        getExpiredBanners (state) {
            return state.expiredBanners;
        },
        getScheduledBanners (state) {
            return state.scheduledBanners;
        },
        getBannerStatusChartDescription (state) {
            return state.bannerStatusChartDescription;
        },
        getBannerStatusData (state) {
            return state.bannerStatusData;
        },
        getBannerFile (state) {
            return state.bannerFile;
        },
        getBannerName (state) {
            return state.bannerName;
        },
        getBanner (state) {
            return state.banner;
        },
        getBannersList (state) {
            return state.bannersList;
        },
    },
    mutations: {
        setActiveBanners (state, activeBanners) {
            state.activeBanners = activeBanners;
        },
        setExpiredBanners (state, expiredBanners) {
            state.expiredBanners = expiredBanners;
        },
        setScheduledBanners (state, scheduledBanners) {
            state.scheduledBanners = scheduledBanners;
        },
        setBannerStatusChartDescription (state, payload) {
            state.bannerStatusChartDescription = payload;
        },
        setBannerStatusData (state, data) {
            state.bannerStatusData.push(data);
        },
        setBannerFile (state, data) {
            state.bannerFile.push(data);
        },
        setBannerName (state, name) {
            state.bannerName = name;
        },
        setBanner (state, data) {
            state.banner = data;
        },
        clearOffBannerFile (state) {
            state.bannerFile = [];
        },
        setBannersList (state, data) {
            state.bannersList = data;
        },
        setUpdateRotationInterval (state, interval) {
            state.bannerFile[0].rotation_interval = interval;
        },
        clearOffBanner (state) {
            state.banner = {};
        },
    },
    actions: {
        async fetchActiveBanners ({ dispatch, commit, state }, { params, updatedAt }) {
            if (state.bannerStatusData.length > 0) {
                return;
            }
            const url = 'api/search/banners';
            try {
                const response = await axios.get(url, { params: params });
                var active = response.data;
                commit('setActiveBanners', { updatedAt: updatedAt, data: active, count: active.length });
                commit('setBannerStatusData', { label: 'Active', count: active.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your active banner count.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchExpiredBanners ({ dispatch, commit, state }, { params, updatedAt }) {
            if (state.bannerStatusData.length > 0) {
                return;
            }
            const url = 'api/search/banners';
            try {
                const response = await axios.get(url, { params: params });
                var expired = response.data;
                commit('setExpiredBanners', { updatedAt: updatedAt, data: expired, count: expired.length });
                commit('setBannerStatusData', { label: 'Expired', count: expired.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your expired banner count.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchScheduledBanners ({ dispatch, commit, state }, { params, updatedAt }) {
            if (state.bannerStatusData.length > 0) {
                return;
            }
            const url = 'api/search/banners';
            try {
                const response = await axios.get(url, { params: params });
                var scheduled = response.data;
                commit('setScheduledBanners', { updatedAt: updatedAt, data: scheduled, count: scheduled.length });
                commit('setBannerStatusData', { label: 'Scheduled', count: scheduled.length });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your scheduled banner count.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        fetchBannerStatusChartDescription ({ state, commit }, payload) {
            if (Object.keys(state.bannerStatusChartDescription).length > 0) {
                return;
            }
            commit('setBannerStatusChartDescription', payload);
        },
        onSelectedContent (context, formData) {
            context.commit('setBannerFile', formData);
        },
        updateRotationInterval (context, interval) {
            context.commit('setUpdateRotationInterval', interval);
        },
        async uploadBannerFile ({ dispatch, commit }, { formData, rotationInterval }) {
            const url = 'api/banners/content/upload';
            try {
                const response = await axios.post(url, formData);
                commit('setBannerFile', { rotation_interval: rotationInterval, formData: response.data });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in Uploading the banner file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        createBannerName (context, name) {
            context.commit('setBannerName', name);
        },
        async createBanner ({ dispatch, commit }, payload) {
            const url = 'api/banners';
            try {
                const response = await axios.post(url, payload);
                commit('setBanner', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearBannerFile ({ commit }) {
            commit('clearOffBannerFile');
        },
        async fetchBannersList ({ dispatch, commit }) {
            const url = 'api/banners';
            try {
                const details = await axios.get(url);
                commit('setBannersList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your banners list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async deleteBanner ({ dispatch }, bannerId) {
            const url = `api/banners/${bannerId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in removing the banner.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
       async linkBannerToBoard ({ dispatch }, payload) {
            const url = 'api/banners/boards';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in linking your banner to digi-boards.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearBanner ({ commit }) {
            commit('clearOffBanner');
        },
        async updateBannerToBoardLink ({ dispatch }, payload) {
            const url = `api/banners/boards/${payload.banner_id}`;
            try {
                await axios.post(url, payload.digi_boards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your banner link with digi-boards.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateBanner ({ dispatch }, payload) {
            const url = `api/banners/${payload.bannerId}`;
            try {
                await axios.post(url, payload.formData);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your campaign contents.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
