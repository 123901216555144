import axios from 'src/app-axios';
import Constants from 'src/constants';

export const support = {
    namespaced: true,
    state: {
      finalIssueList: [],
    },
    getters: {
      getFinalIssueList (state) {
        return state.finalIssueList;
      },
    },
    mutations: {
      setFinalIssueList (state, data) {
        state.finalIssueList = data;
      },
      clearIssueArray (state) {
        state.finalIssueList = [];
      },
    },
    actions: {
      async createIssueTicket ({ dispatch }, payload) {
        const url = 'api/support/tickets';
        try {
          await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating your issue ticket.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchSupportTickets ({ dispatch, commit }, { params }) {
        const url = 'api/support/tickets';
        try {
            const response = await axios.get(url, { params: params });
              commit('setFinalIssueList', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your support tickets.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async deleteIssueTicket ({ dispatch }, ticketId) {
        const url = `api/support/tickets/${ticketId}`;
        try {
            await axios.delete(url);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in deleting this ticket.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      clearIssueList ({ commit }) {
        commit('clearIssueArray');
      },
    },
};
