import axios from 'src/app-axios';
import Constants from 'src/constants';

export const orgsettings = {
    namespaced: true,
    state: {
        orgSettings: [],
    },
    getters: {
        getOrgSettings (state) {
            return state.orgSettings;
        },
    },
    actions: {
        async fetchOrgSettings ({ dispatch, commit, state }) {
            const url = 'api/organization-modules';
            try {
                const settings = await axios.get(url);
                commit('setOrgSettings', settings.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your organization details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
    mutations: {
        setOrgSettings (state, orgSettings) {
            state.orgSettings = orgSettings;
        },
    },
};
