export const records = {
    namespaced: true,
    state: {
        records: [],
        selectedRecords: [],
    },
    getters: {
        getRecords (state) {
            return state.records;
        },

        getSelectedRecords (state) {
            return state.selectedRecords;
        },
    },
    mutations: {
        addRecords (state, payload) {
            state.records = payload;
        },
        addSelectedRecord (state, payload) {
            state.selectedRecords.push(payload);
        },

        clearRecords (state) {
            state.records = [];
        },
    },
    actions: {
        onRecordsAdd (context, records) {
            context.commit('clearRecords');
            context.commit('addRecords', records);
        },

        onSelectedRecordAdd (context, record) {
            context.commit('addSelectedRecord', record);
        },
    },
};
