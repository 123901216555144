export const notifications = {
    namespaced: true,
    state: {
        messages: [],
        userRead: false,
        unReadCount: 0,
    },
    getters: {
        getMessages (state) {
            return state.messages;
        },
        getUserRead (state) {
            return state.userRead;
        },
        getUnReadCount (state) {
            return state.unReadCount;
        },
    },
    mutations: {
        addMessage (state, message) {
            state.messages.push(message);
        },
        addUnReadCount (state, count) {
            state.unReadCount += count;
        },
        updateUserRead (state, status) {
            state.userRead = status;
        },
        updateUnReadCount (state, count) {
            state.unReadCount = count;
        },
    },
    actions: {
        recordMessage (context, message) {
            context.commit('addUnReadCount', 1);
            context.commit('addMessage', message);
        },
        updateUserRead (context, readStatus) {
            context.commit('updateUserRead', readStatus);
        },
        updateUnReadCount (context, count) {
            context.commit('updateUnReadCount', count);
        },
    },
};
