<template>
  <v-container fluid>
    <v-card
      color="#ECEFF1"
      elevation="3"
      class="status-card"
    >
      <div class="main-div">
        <v-icon
          class="status-icon"
          size="30"
          :color="iconColor"
        >
          {{ statusIcon }}
        </v-icon>
        <p class="my-stats-card-title-style">
          {{ title }} <br>
          <span class="my-stats-count-style">{{ value }}</span>
        </p>
      </div>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'MaterialStatsOutlineCard',

  props: {
    title: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    statusIcon: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped>
.status-card {
  border-bottom: 5px solid #546E7A !important;
  border-radius: 15px 15px 15px 15px;
}
.my-stats-card-title-style {
  color: #37474F;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
}
.my-stats-count-style {
  color: #37474F;
  font-weight: bold;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}
.status-icon {
  margin-left: 10px;
  margin-bottom: 10px;
}
.main-div {
 display: flex;
}
</style>
